// @flow
import React from 'react'
import { ImageBlock, useBlock } from '@shift-marketing/shift-one-website-editor'

import propertiesSchema from './properties.schema.json'

import styles from './styles.module.scss'

export default function TopBar () {
  const { properties, ref } = useBlock('header', propertiesSchema)

  return (
        <div ref={ref} className={styles.container} style={{ backgroundColor: properties.backgroundColor }}>
            <div className={styles.logo}>
                <ImageBlock
                    blockId="top.logo"
                    defaultImage="$.logo.regular"
                    maxWidth={400}
                    resize="both"
                    title="Logo"
                />
            </div>
        </div>
    )
}

