// @flow
import React from 'react'

import { withTranslation } from 'react-i18next'
import transDomain from './translations/index.translations'

import styles from './styles.module.scss'

function CampaignNotFound (props) {
    const { t } = props

    return (
        <div className={styles.container}>
            <div className={styles.inner}>
                <h1>404</h1>

                <h4>
                    {t('title')}
                </h4>

                <p>
                    {t('description')}
                </p>
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(CampaignNotFound)
