// @flow
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import MediaQuery from 'react-responsive'

import transDomain from './translations/index.translations'

import * as Form from 'components/Form'
import withForm, { WithFormProps } from 'components/Form/Helpers/FormHOC'
import Steps from 'components/Steps'

import supportedThemes from './themes/__supportedThemes'

import styles from './styles.module.scss'

type Props = WithFormProps | {
    values: {
        firstName: ?string,
        lastName: ?string,
        email: ?string,
        phoneNumber: ?string
    }
}

function Step1 (props: Props) {
    const { t } = useTranslation(transDomain)

    const phoneField = React.useMemo(() => {
        if (props.values.mobilePhone) {
            return 'mobilePhone'
        }

        if (props.values.homePhone) {
            return 'homePhone'
        }

        for (let field in props.values) {
            if (field.endsWith('Phone') && props.values[field]) {
                return field
            }
        }

        return 'mobilePhone'
    }, [props.values])

    return (
        <>
            <Steps id={'steps.1'} step={1} steps={3} description={t('description')} />
            <Form.Row>
                <Form.RowColumns columnsTablet={2} columnsMobile={2}>
                    <Form.RowColumn className={styles.row1}>
                        <Form.Field
                            // error={props.errors.firstName || null}
                            defaultValue={props.values.firstName || null}
                            onChange={value => {
                                props.handleFieldChange('firstName', value)
                            }}
                            type='text'
                            label={t('label.firstName')}
                            placeholder={t('placeholder.firstName')}
                        />
                    </Form.RowColumn>
                    <Form.RowColumn className={styles.row2}>
                        <Form.Field
                            // error={props.errors.lastName || null}
                            defaultValue={props.values.lastName || null}
                            onChange={value => {
                                props.handleFieldChange('lastName', value)
                            }}
                            type='text'
                            label={t('label.lastName')}
                            placeholder={t('placeholder.lastName')}
                        />
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            <Form.Row>
                <Form.RowColumns columnsTablet={2}>
                    <Form.RowColumn size={1} className={styles.row3}>
                        <Form.Field
                            defaultValue={props.values[phoneField] || null}
                            onChange={value => {
                                props.handleFieldChange(phoneField, value)
                            }}
                            type='phone'
                            label={t('label.homePhone')}
                            placeholder={t('placeholder.homePhone')}
                            required={false}
                        />
                    </Form.RowColumn>
                    <Form.RowColumn size={1} className={styles.row4}>
                        <Form.Field
                            defaultValue={props.values.email || null}
                            onChange={value => {
                                props.handleFieldChange('email', value)
                            }}
                            type='email'
                            label={t('label.emailAddress')}
                            placeholder={t('placeholder.emailAddress')}
                         />
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            <Form.Row>
                <MediaQuery minWidth={1024}>
                    <Form.SubmitBlock
                      isLoading={props.isLoading}
                      blockId={'step1.submit'}
                      label={t('continue')}
                      style={{ minWidth: 0, width: '100%', maxWidth: '200px', marginTop: '0.5em', marginBottom: '1.5em' }}
                    />
                </MediaQuery>
                <MediaQuery maxWidth={1023}>
                    <Form.SubmitBlock
                      isLoading={props.isLoading}
                      blockId={'step1.submit'}
                      label={t('continueAlternate')}
                      style={{ maxWidth: '200px', marginTop: '0.5em', marginBottom: '1.5em' }}
                    />
                </MediaQuery>
            </Form.Row>
        </>
    )
}

Step1.defaultProps = {
    values: {
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null
    }
}

export default withForm()(withTheme(supportedThemes)(Step1))
