// @flow
import React from 'react'
import cn from 'classnames'
import { withTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import { useBlock, TextBlock } from '@shift-marketing/shift-one-website-editor'

import { createClickActivity } from 'Actions'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'
import propertiesSchema from './properties.schema.json'

type Props = {
    id: string,
    step: number,
    description?: string,
};

type StepProps = {
    t: Function,
    theme: { [string]: mixed },
    step: number,
    activeColor: string,
    currentStep: number,
}

const Step = ({ theme, step, activeColor, currentStep }: StepProps) => {
    const completed = currentStep > step
    const current = currentStep === step
    const classes = cn({ [styles.current]: current, [styles.completed]: completed })
    const isActive = current ? { opacity: 1 } : {}

    return (
        <div className={styles.stepLabel}>
            <span className={classes} style={{ backgroundColor: activeColor, opacity: 0.15, ...isActive }}>
            </span>
        </div>
    )
}

function Steps (props: Props) {
    const { ref, properties } = useBlock('steps', propertiesSchema)
    const { id, t, theme, step, description, title } = props

    const getSteps = React.useCallback(() => {
        const { steps } = props
        let generetedSteps = []

        for (let i = 1; i <= steps; ++i) {
            generetedSteps.push(i)
        }
        return generetedSteps
    }, [props])

    return (
        <div ref={ref} className={cn(styles.container, props.className)}>
            {title &&
                <div className={styles.title} style={{
                    color: theme.titleColor
                }}>
                    <TextBlock
                        blockId={`${id}.title`}
                        defaultContent={`<h4>${title}</h4>`}
                        onAnchorClick={createClickActivity}
                    />
                </div>
            }

            {description &&
                <div className={styles.description} style={{
                    color: theme.descriptionColor
                }}>
                    <TextBlock
                        blockId={`${id}.description`}
                        defaultContent={`<p>${description}</p>`}
                        onAnchorClick={createClickActivity}
                    />
                </div>
            }

            <div className={styles.stepsContainer}>
                {getSteps().map(item => (
                    <Step
                        t={t}
                        key={item}
                        step={item}
                        currentStep={step}
                        theme={theme}
                        activeColor={properties.activeColor}
                    />
                ))}
            </div>
        </div>
    )
}

Steps.defaultProps = {
    step: 1
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Steps))
