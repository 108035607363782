// @flow
import React from 'react'
import { BackgroundImageBlock, useBlock } from '@shift-marketing/shift-one-website-editor'

import {
    BodyLayout,
    LeftSide,
    RightSide
} from 'components/MainLayout'
import Landing from 'components/Landing'
import VideoPlayButton from 'components/VideoPlayButton'
import withTheme from 'hoc/withTheme'

import themes, { type Theme } from './themes/__supportedThemes'
import propertiesSchema from './properties.schema.json'

import styles from './styles.module.scss'
import defaultImage from './images/sidebar.jpg'

type Props = {
    history: any,
    location: any,
    match: any,
    theme: Theme
}

function LandingPage (props: Props) {
    const { properties, ref } = useBlock('landing.rightSide', propertiesSchema)

    return (
        <BodyLayout>
            <LeftSide>
                <BackgroundImageBlock
                    className={styles.image}
                    blockId="sidebar.image"
                    defaultImage={defaultImage}
                    defaultPositionY={40}
                    title="Sidebar image"
                />

                <VideoPlayButton/>
            </LeftSide>

            <RightSide reference={ref} style={{ backgroundColor: properties.backgroundColor }}>
                <Landing
                    onSubmit={(data, done) => {
                        window.scrollTo(0, 0)
                        props.history.push(`${props.match.params.url}/step-1${props.location.search}`)
                    }}
                    initialValues={{}}
                />
            </RightSide>
        </BodyLayout>
    )
}

export default withTheme(themes)(LandingPage)
