import React from 'react'
import { Redirect } from 'react-router-dom'
import { useBlock } from '@shift-marketing/shift-one-website-editor'
import { BackgroundImageBlock } from '@shift-marketing/shift-one-website-editor'

import { Store } from 'Store'
import { BodyLayout, LeftSide, RightSide } from 'components/MainLayout'
import Success from 'components/LeadGenForm/Success'
import withTheme from 'hoc/withTheme'

import supportedThemes from './themes/__supportedThemes.js'
import propertiesSchema from './properties.schema.json'
import defaultImage from './images/sidebar.jpg'

import styles from './styles.module.scss'

export default withTheme(supportedThemes)(({ location, match, theme }) => {
    const { state } = React.useContext(Store)
    const { properties, ref } = useBlock('success.rightSide', propertiesSchema)

    if (!state.lead) {
        return <Redirect to={`/${match.params.url}${location.search}`} push />
    }
    return (
        <BodyLayout className={styles.container}>
            <LeftSide>
                <BackgroundImageBlock
                    className={styles.image}
                    blockId="success.sidebar.image"
                    defaultImage={defaultImage}
                    defaultPositionY={50}
                    defaultPositionX={50}
                    title="Sidebar image"
                />
            </LeftSide>

            <RightSide reference={ref} style={{ backgroundColor: properties.backgroundColor }}>
                <Success state={state} />
            </RightSide>
        </BodyLayout>
    )
})
