// @flow
import React from 'react'
import { BodyLayout, LeftSide, RightSide } from 'components/MainLayout'
import { useBlock } from '@shift-marketing/shift-one-website-editor'
import { Store } from 'Store'
import Step1 from 'components/LeadGenForm/Step1'
import { Redirect } from 'react-router-dom'
import InnerPageSidebar from 'components/InnerPageSidebar'

import { updateLead } from 'Actions'
import withTheme from 'hoc/withTheme'

import themes, { type Theme } from './themes/__supportedThemes'
import propertiesSchema from './properties.schema.json'

import styles from './styles.module.scss'

function Step1Page (props: { history: any, location: any, match: any, theme: Theme }) {
    const { state, dispatch } = React.useContext(Store)
    const { properties, ref } = useBlock('step1.rightSide', propertiesSchema)
    const [submitted, setSubmitted] = React.useState(false)

    if (submitted) {
        return <Redirect to={`step-2${props.location.search}`} push />
    }

    if (!state.lead) {
        return <Redirect to={`/${props.match.params.url}${props.location.search}`} push />
    }

    return (
        <BodyLayout className={styles.container}>
            <LeftSide>
                <InnerPageSidebar/>
            </LeftSide>
            <RightSide reference={ref} style={{ backgroundColor: properties.backgroundColor }}>
                <Step1
                    onSubmit={async (data, done) => {
                        try {
                            let lead = {
                                fields: data,
                                isComplete: false
                            }
                            await updateLead(dispatch, state.code, lead)
                            setSubmitted(true)
                            window.scrollTo(0, 0)
                        } catch (err) {
                            alert('Oops something went wrong')
                            console.error(err)
                            done(err)
                        }
                    }}
                    initialValues={state.lead && state.lead.fields ? state.lead.fields : {}}
                />
            </RightSide>
        </BodyLayout>
    )
}

export default withTheme(themes)(Step1Page)
