// @flow
import * as React from 'react'
import cn from 'classnames'
import { TextBlock } from '@shift-marketing/shift-one-website-editor'
import { withTranslation } from 'react-i18next'

import withTheme from 'hoc/withTheme'
import { createClickActivity } from 'Actions'
import { Store } from 'Store'
import { formatDateTime } from 'i18n/DateFormat'

import transDomain from './translations/index.translations'
import supportedThemes from './themes/__supportedThemes.js'

import styles from './styles.module.scss'

const Separator = ({ small }) => <div className={cn(styles.separator, { [styles.separatorSmall]: small })}>&nbsp;:&nbsp;</div>

const renderDigits = value => value.split('').map((d, key) => (<span key={key} className={styles.digit}>{d}</span>))

const Unit = ({ value, small }) => {
    return (
        <div className={cn(styles.unitContainer, {
            [styles.small]: small
        })}>
            {renderDigits(value)}
        </div>
    )
}

type Difference = {
    seconds: string,
    minutes: string,
    hours: string,
    days: string
}

function formatDifference (difference: number): Difference {
    let seconds = Math.max(0, Math.floor((difference / 1000) % 60))
    let minutes = Math.max(0, Math.floor((difference / 1000 / 60) % 60))
    let hours = Math.max(0, Math.floor((difference / (1000 * 60 * 60)) % 24))
    let days = Math.max(0, Math.floor(difference / (1000 * 60 * 60 * 24)))

    return {
        days: days > 99 ? ('0' + days).slice(-3) : ('0' + days).slice(-2),
        hours: ('0' + hours).slice(-2),
        minutes: ('0' + minutes).slice(-2),
        seconds: ('0' + seconds).slice(-2)
    }
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(function Countdown (props) {
    const { t, theme, small } = props
    const { state } = React.useContext(Store)

  const [formattedDate, setFormattedDate] = React.useState(formatDifference(props.date.getTime() - (new Date()).getTime()))

    React.useEffect(() => {
        let interval = setInterval(() => {
            const difference = props.date.getTime() - (new Date()).getTime()

            if (difference <= 0) { clearInterval(interval); return }

            setFormattedDate(formatDifference(difference))
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    }, [props.date])

    return (
      <div className={styles.wrapper}>
          <div className={styles.eventExpiry}>
              <div style={{ color: theme.descriptionColor }}>
                <TextBlock
                  blockId={'countdown.message'}
                  defaultContent={`
                      ${t('event_expiry')} ${formatDateTime(state.campaign.expiryDate, 'long')}
                  `}
                  onAnchorClick={createClickActivity}
                />
              </div>
          </div>

          <span className={styles.countdown} style={{ color: theme.timerColor }}>
            <Unit small={small} value={formattedDate.days} label={t('days')} />
            <Separator small={small} />
            <Unit small={small} value={formattedDate.hours} label={t('hours')} />
            <Separator small={small} />
            <Unit small={small} value={formattedDate.minutes} label={t('minutes')} />
            <Separator small={small} />
            <Unit small={small} value={formattedDate.seconds} label={t('seconds')} />
        </span>
      </div>
    )
}))
