import React from 'react'
import { Redirect } from 'react-router-dom'
import { useBlock } from '@shift-marketing/shift-one-website-editor'

import { Store } from 'Store'
import { updateLead } from 'Actions'

import { BodyLayout, LeftSide, RightSide } from 'components/MainLayout'
import InnerPageSidebar from 'components/InnerPageSidebar'
import Step2 from 'components/LeadGenForm/Step2'

import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes'
import propertiesSchema from './properties.schema.json'

import styles from './styles.module.scss'

export default withTheme(supportedThemes)(({ history, location, match, theme }) => {
    const { state, dispatch } = React.useContext(Store)
    const { properties, ref } = useBlock('step2.rightSide', propertiesSchema)

    const [submitted, setSubmitted] = React.useState(false)

    if (submitted) {
        return <Redirect to={`step-3${location.search}`} push />
    }

    if (!state.lead) {
        return <Redirect to={`/${match.params.url}${location.search}`} push />
    }

    return (
        <BodyLayout className={styles.container}>
            <LeftSide>
                <InnerPageSidebar/>
            </LeftSide>
            <RightSide reference={ref} style={{ backgroundColor: properties.backgroundColor }}>
                <Step2
                    onSubmit={async (data, done) => {
                        try {
                            let lead = {
                                fields: data,
                                isComplete: false
                            }
                            await updateLead(dispatch, state.code, lead)
                            setSubmitted(true)
                            window.scrollTo(0, 0)
                        } catch (err) {
                            alert('Oops something went wrong')
                            console.error(err)
                            done(err)
                        }
                    }}
                    initialValues={state.lead && state.lead.fields ? state.lead.fields : {}}
                />
            </RightSide>
        </BodyLayout>
    )
})
