import React from 'react'
import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import cn from 'classnames'

function SwitchSelector ({ children, options, selected, handleInputChange, required, value, theme, labelFormat, trans }) {
    return (
        <div className={styles.container} style={{
            backgroundColor: theme.backgroundColor,
        }}>
            <div className={styles.uidata}>
                {options.map(text => <div onClick={() => handleInputChange(text)} key={text} className={cn(styles.option, {
                    [styles.active]: selected === text,
                    [styles.fullWidth]: options.length === 1,
                    [styles.borderLess]: theme.borderLess,
                })} style={{ color: 'var(--color-selected)', '--switch-selector-border-color': 'var(--color-selected)' }}>
                    {trans ? trans(labelFormat.replace('%name%', text)) : text}
                </div>)}
            </div>
            <span className={styles.maskValidator}>
                <input required={required} value={selected} type="text"/>
            </span>
        </div>
    )
}

SwitchSelector.defaultProps = {
    options: [], // max length 2,
    selected: null // 0 xor 1
}

export default withTheme(supportedThemes)(SwitchSelector)
