/* eslint-disable quotes */
import * as React from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import MediaQuery from 'react-responsive'
import { TextBlock } from '@shift-marketing/shift-one-website-editor'

import { createClickActivity } from 'Actions'

import transDomain from './translations/index.translations'

import * as Form from 'components/Form'
import withForm from 'components/Form/Helpers/FormHOC'
import Steps from 'components/Steps'
import DonateOption from 'components/DonateOption'
import SwitchSelector from 'components/SwitchSelector'
import ModalContainer from 'components/ModalContainer'
import { Currency } from 'i18n/NumberFormatter'
import messageImage from './images/messageImage.jpg'

import supportedThemes from './themes/__supportedThemes'
import { Store } from 'Store'

import styles from './styles.module.scss'

const oneTimeDonationOption = 'one-time'
const monthlyDonationOption = 'monthly'

function Step3 ({ theme, values, isLoading, byEmail, byPhone, toStep1, toEmail, toPhone, handleFieldChange }) {
    const [show, setShow] = React.useState(byEmail || byPhone)
    const [ otherAmountActive, setOtherAmountActive ] = React.useState(null)
    const { t } = useTranslation(transDomain)
    const { state } = React.useContext(Store)

    const defaultDonationAmount = values.donationAmount || state.campaign.website.defaultDonationAmount

    const currencyFormatter = React.useMemo(
        () => Currency(state.currency, state.locale),
        [state.currency, state.locale]
    )

    const donationAmountOptions = React.useMemo(() => {
        return state.campaign.website.donationAmounts
    }, [state.campaign.website.donationAmounts])

    const paymentGatewayConfig = React.useMemo(() => {
        const index = Object.keys(state.campaign['payment-gateways'])[0]
        return state.campaign['payment-gateways'][index]
    }, [state.campaign])

    const handleSetOtherAmountActive = React.useCallback(() => {
        setOtherAmountActive(true)
    }, [])

    const handleSetOtherAmountDisable = React.useCallback(() => {
        setOtherAmountActive(false)
    }, [])

    const handleComments = React.useCallback(value => {
        return handleFieldChange('comments', value)
    }, [handleFieldChange])

    values.anonymousDonation = values.anonymousDonation || false
    values.matchingGiftCompany = values.matchingGiftCompany || 'none'

    React.useEffect(() => {
        if (paymentGatewayConfig?.monthlySubscriptionActive === false) {
            handleFieldChange('donationType', oneTimeDonationOption)
        }
    }, [handleFieldChange, paymentGatewayConfig])

    React.useEffect(() => {
        if ((!donationAmountOptions.includes(defaultDonationAmount)) && defaultDonationAmount) handleSetOtherAmountActive()
        if (defaultDonationAmount && !values.donationAmount) handleFieldChange('donationAmount', defaultDonationAmount)
    }, [values.donationAmount, donationAmountOptions, defaultDonationAmount, handleSetOtherAmountActive, handleFieldChange])

    return (
        <>
            <ModalContainer show={show} onClickOverlay={() => setShow(true)}>
                {byPhone &&
                    <div className={styles.infoContainer} style={{ backgroundImage: `url(${messageImage})` }}>
                        <h3 className={styles.infoTitle}>Phone call donation</h3>
                        <div className={styles.infoBody}>
                            <p>{t('byPhone.title')}</p>
                            <span>{t('byPhone.thanksMessage')}</span>
                        </div>
                        <span onClick={toStep1}>
                            <Form.Submit
                                isSubmit={false}
                                isLoading={isLoading}
                                label={t('label.continue')}
                                style={{ background: theme.background, color: theme.color, marginTop: '1.62em' }}
                            />
                        </span>
                        <div className={styles.infoMessage} onClick={toEmail}>
                            <TextBlock
                                blockId={'step3.emailAlternative'}
                                defaultContent={`<p>${t('byEmail.alternative')}</p>`}
                                onAnchorClick={createClickActivity}
                            />
                        </div>
                    </div>
                }

                {byEmail &&
                    <div className={styles.infoContainer} style={{ backgroundImage: `url(${messageImage})` }}>
                        <h3 className={styles.infoTitle}>Donate by mail</h3>
                        <div className={styles.infoBody}>
                            <p>{t('byPhone.title')}</p>
                            <span>{t('byPhone.thanksMessage')}</span>
                        </div>
                        <span onClick={toStep1}>
                            <Form.Submit
                                isSubmit={false}
                                isLoading={isLoading}
                                label={t('label.continue')}
                                style={{ background: theme.background, color: theme.color, marginTop: '1.62em' }}
                            />
                        </span>
                        <div className={styles.infoMessage} onClick={toPhone}>
                            <TextBlock
                                blockId={'step3.phoneAlternative'}
                                defaultContent={`<p>${t('byPhone.alternative')}</p>`}
                                onAnchorClick={createClickActivity}
                            />
                        </div>
                    </div>
                }

            </ModalContainer>

            <Steps id={'steps.3'} step={3} steps={3} description={'Select the amount you wish to donate.'} title={''}/>

            {paymentGatewayConfig?.monthlySubscriptionActive && (
                <Form.Row>
                    <Form.RowColumns columnsTablet={1}>
                        <Form.RowColumn size={1}>
                            <SwitchSelector
                                selected={values.donationType}
                                options={[oneTimeDonationOption, monthlyDonationOption]}
                                required
                                handleInputChange={value => handleFieldChange('donationType', value)}
                                trans={t}
                                labelFormat='donationType.option.%name%'
                            />
                            <div className={styles.message}>
                                <TextBlock
                                    blockId={'step3.donationType.description'}
                                    defaultContent={`
                                        <p>
                                            ${t('donation_time', {
                                                type: values.donationType
                                            })}
                                        </p>
                                    `}
                                    onAnchorClick={createClickActivity}
                                />
                            </div>
                        </Form.RowColumn>
                    </Form.RowColumns>
                </Form.Row>
            )}

            <Form.Row>
                <Form.RowColumns columnsTablet={1}>
                    <Form.RowColumn size={1}>
                        <div className={cn(styles.row, styles.donateAmountGroup)}>
                            {donationAmountOptions.map((donationAmount) => (
                                <DonateOption
                                    key={donationAmount}
                                    center
                                    small
                                    title={currencyFormatter.format(donationAmount)}
                                    onClick={() => {
                                        // if a user changes the donation amount after he selected a designation
                                        if (values.donationDesignations && values.donationDesignations.length === 1) {
                                            const designation = values.donationDesignations[0]
                                            designation.value = donationAmount
                                            handleSetOtherAmountDisable()
                                            // update first field, use callback to update extra state
                                            handleFieldChange('donationAmount', donationAmount, () => {
                                                handleFieldChange('donationDesignations', [designation])
                                            })
                                        } else {
                                            handleSetOtherAmountDisable()
                                            handleFieldChange('donationAmount', donationAmount)
                                        }
                                    }}
                                    active={values.donationAmount === donationAmount && !otherAmountActive}
                                />
                            ))}

                            <DonateOption center small title='Other'
                                onClick={() => {
                                    // if a user changes the donation amount after he selected a designation
                                    if (values.donationDesignations && values.donationDesignations.length === 1) {
                                        // prevent double click on "Other" value must not be null or 0
                                        const designation = values.donationDesignations[0]
                                        designation.value = values.donationAmount
                                        // update first field, use callback to update extra state
                                        handleSetOtherAmountActive()
                                        handleFieldChange('donationAmount', designation.value, () => {
                                            handleFieldChange('donationDesignations', [designation])
                                        })
                                    } else {
                                        handleSetOtherAmountActive()
                                        handleFieldChange('donationAmount', null)
                                    }
                                }}
                                active={otherAmountActive}
                            />
                        </div>
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            {((otherAmountActive === true || (values.donationAmount && !donationAmountOptions.includes(values.donationAmount)))) &&
                <Form.Row>
                    <Form.RowColumns columnsTablet={1}>
                        <Form.RowColumn size={1}>
                            <Form.CurrencyField
                                label={t('label.amount')}
                                type='currency'
                                defaultValue={values.donationAmount || ''}
                                onChange={value => {
                                    // if a user changes the donation amount after he selected a designation
                                    if (values.donationDesignations && values.donationDesignations.length === 1) {
                                        const designation = values.donationDesignations[0]
                                        designation.value = value
                                        // update first field, use callback to update extra state
                                        handleFieldChange('donationAmount', value, () => {
                                            handleFieldChange('donationDesignations', [designation])
                                        })
                                    } else {
                                        handleFieldChange('donationAmount', value)
                                    }
                                }}
                                currency={state.currency}
                                locale={state.locale}
                                required={typeof values.donationAmount !== 'undefined' && !donationAmountOptions.includes(values.donationAmount)}
                            />
                        </Form.RowColumn>
                    </Form.RowColumns>
                </Form.Row>
            }

            {theme.hasDonationDesignations && state.campaign.website.donationDesignations && state.campaign.website.donationDesignations.length > 0 && (
                <Form.Row>
                    <Form.RowColumns columnsTablet={1}>
                        <Form.RowColumn size={0.5}>
                            <Form.Field label={'Please apply my gift to'}>
                                <Form.Dropdown
                                    placeholder={'Select'}
                                    options={state.campaign.website.donationDesignations || ['Greatest Need', 'Other (indicate in the comment box below)']}
                                    required={true}
                                    onChange={value => {
                                        handleFieldChange('donationDesignations', (value !== '' && value !== null) ? [{
                                            type: value,
                                            value: values.donationAmount,
                                            other: value.indexOf('Other') === 0
                                        }] : null)
                                    }}
                                    defaultValue={values.donationDesignations
                                        ? (values.donationDesignations[0].type
                                            ? values.donationDesignations[0].other
                                                ? 'Other'
                                                : values.donationDesignations[0].type
                                            : null
                                        )
                                        : null
                                    }
                                />
                            </Form.Field>
                        </Form.RowColumn>
                    </Form.RowColumns>
                </Form.Row>
            )}

            {(values.donationDesignations?.[0].type.indexOf('Other') === 0 || values.donationDesignations?.[0].other) &&
                <Form.Row>
                    <Form.RowColumns columnsTablet={1}>
                        <Form.RowColumn size={1}>
                            <Form.Field
                                required
                                defaultValue={values.donationDesignations
                                    ? (values.donationDesignations[0].type
                                        ? values.donationDesignations[0].type.indexOf('Other') === 0
                                            ? ''
                                            : values.donationDesignations[0].type
                                        : null
                                    )
                                    : null
                                }
                                onChange={value => {
                                    handleFieldChange('donationDesignations', (value !== '' && value !== null) ? [{
                                        type: value,
                                        value: values.donationAmount,
                                        other: true
                                    }] : null)
                                }}
                                type='text'
                                label={'Other designation'}
                                placeholder={'Enter other designation'}
                            />
                        </Form.RowColumn>
                    </Form.RowColumns>
                </Form.Row>
            }

            <Form.Row>
                <Form.RowColumns columnsTablet={1}>
                    <Form.RowColumn size={1}>
                        <Form.Field label={'Matching gift'}>
                            <div className={styles.donateOptionGroup}>
                                <DonateOption center small title='None'
                                    onClick={() => handleFieldChange('matchingGiftCompany', 'none')}
                                    active={values.matchingGiftCompany === 'none'}
                                    className={styles.option1}
                                />
                                <div className={styles.option2} />
                                <DonateOption center small title='My company'
                                    onClick={() => handleFieldChange('matchingGiftCompany', 'own-company')}
                                    active={values.matchingGiftCompany === 'own-company'}
                                    className={styles.option3}
                                />
                                <div className={styles.option4} />
                                <DonateOption center small title="Spouse’s company"
                                    onClick={() => handleFieldChange('matchingGiftCompany', 'spouse-company')}
                                    active={values.matchingGiftCompany === 'spouse-company'}
                                    className={styles.option5}
                                />
                            </div>
                        </Form.Field>
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            {values.matchingGiftCompany !== 'none' &&
                <Form.Row>
                    <Form.RowColumns columnsTablet={1}>
                        <Form.RowColumn size={1}>
                            <Form.Field
                                defaultValue={values.matchingGiftCompanyName || null}
                                onChange={value => {
                                    handleFieldChange('matchingGiftCompanyName', `${value}`)
                                }}
                                type='text'
                                label={t('matchingGiftCompanyName.label')}
                                placeholder={t('matchingGiftCompanyName.placeholder')}
                            />
                        </Form.RowColumn>
                    </Form.RowColumns>
                </Form.Row>
            }

            {theme.hasTextArea && (
                <Form.Row>
                    <Form.RowColumns columnsTablet={1}>
                        <Form.RowColumn size={1}>
                            <Form.Field label={t('comments.label')}>
                                <Form.Textarea
                                    defaultValue={values.comments || null}
                                    onChange={handleComments}
                                    type='textarea'
                                    required={false}
                                    rows={9}
                                    placeholder={t('comments.placeholder')}
                            />
                            </Form.Field>
                        </Form.RowColumn>
                    </Form.RowColumns>
                </Form.Row>
            )}

            <Form.Row>
                <Form.RowColumns columnsTablet={1}>
                    <Form.RowColumn size={1}>
                        <span className={styles.chekbox}>
                            <Form.Checkbox
                                defaultChecked={values.anonymousDonation}
                                label={'If this box is checked, you will remain an anonymous donor.'}
                                onChange={() => handleFieldChange('anonymousDonation', !values.anonymousDonation)}
                            />
                        </span>
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            <Form.Row>
                <MediaQuery minWidth={1024}>
                    <Form.SubmitBlock
                        isLoading={isLoading}
                        blockId={'step3.submit'}
                        label={t('continue')}
                        style={{ minWidth: 0, width: '100%', maxWidth: '240px', marginTop: '0.5em', marginBottom: '1.5em' }}
                    />
                </MediaQuery>
                <MediaQuery maxWidth={1023}>
                    <Form.SubmitBlock
                        isLoading={isLoading}
                        blockId={'step3.submit'}
                        label={t('continueAlternate')}
                        style={{ maxWidth: '240px',marginTop: '0.5em', marginBottom: '1.5em' }}
                    />
                </MediaQuery>
            </Form.Row>
        </>
    )
}

Step3.defaultProps = {
    values: {
        donationAmount: null,
        matchingGiftCompany: 'none',
        matchingGiftCompanyName: null,
        donationDesignations: null,
        anonymousDonation: false
    }
}

export default withForm()(withTheme(supportedThemes)(Step3))
