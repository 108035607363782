// @flow
import * as React from 'react'

import cn from 'classnames'

import styles from './styles.module.scss'

export function LeftSide (props) {
    return (
        <div className={cn(styles.leftSide, props.className)} style={props.style}>
            {props.children}
        </div>
    )
}

export function RightSide (props) {
    return (
        <div ref={props.reference} className={cn(styles.rightSide, props.className)} style={props.style}>
            {props.children}
        </div>
    )
}

export function BodyLayout (props) {
    return (
        <div ref={props.reference} className={cn(styles.body, props.className)} style={props.style}>
            {props.children}
        </div>
    )
}

function MainLayout (props) {
    return (
        <div
            className={cn(styles.container, props.className)}
            style={props.style}>
            {props.children}
        </div>
    )
}

export default MainLayout
