// @flow
import React from 'react'
import { BackgroundImageBlock } from '@shift-marketing/shift-one-website-editor'

import defaultImage from './images/desktop.jpg'

import styles from './styles.module.scss'

export default function InnerPageSidebar () {
    return <>
        <BackgroundImageBlock
            className={styles.image}
            blockId="inner-page.sidebar.image"
            defaultImage={defaultImage}
            defaultPositionY={5}
            title="Sidebar image"
        />
    </>
}
