import * as React from 'react'
import withTheme from 'hoc/withTheme'

import { createClickActivity } from 'Actions'
import { Store } from 'Store.js'
import withForm from 'components/Form/Helpers/FormHOC'
import StepLayout from 'components/StepLayout'
import supportedThemes from './themes/__supportedThemes'
import styles from './styles.module.scss'
import { TextBlock } from '@shift-marketing/shift-one-website-editor'

function Success (props) {
    const { state } = React.useContext(Store)
    let valuesSchema = React.useMemo(() => {
        const schema = JSON.parse(JSON.stringify(state.campaign.mergeTags)) // deep clone

        if (!schema.properties.hasOwnProperty('donationAmount')) {
            schema.properties.donationAmount = {
                type: 'number',
                title: 'Donation amount',
            }
        }

        return schema
    }, [state.campaign.mergeTags])
    return (
        <StepLayout>
            <div className={styles.success}>
                <h3
                    style={{
                        '--primary-color': props.theme.primaryColor,
                    }}
                    className={styles.text}
                >
                    <TextBlock
                        blockId={'success.message'}
                        defaultContent={`
                            Thank you, {firstName}. <br />
                            With your \${donationAmount} donation, <br />
                            we can make students’ dreams come true.
                        `}
                        values={{ ...state.lead.fields }}
                        valuesSchema={valuesSchema}
                        onAnchorClick={createClickActivity}
                    />
                </h3>
            </div>
        </StepLayout>
    )
}

export default withForm(true, false)(withTheme(supportedThemes)(Success))
